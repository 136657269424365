import { Component, Watch } from 'vue-property-decorator';
import { OsTable, OsQueryPlan } from '@/components';
import { OsTableColumn, OsTableOption, RowOperation } from '@/components/os-table/os-table';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import { ImportRes, LogisticsList, LogisticsListQuery } from '@/resource/model';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import {
  messageError,
  showWarningConfirm,
  translation,
  dateFormat,
  downloadFileByBlob,
  handleImportError,
  messageErrors,
  debounce
} from '@/utils';
import { Message } from 'element-ui';
import { electronicExpressWaybillService, logisticsService } from '@/api';
import { mixins } from 'vue-class-component';
import { PagingMixin } from '@/mixins/paging';
import {
  DeliveryModeEnum,
  LogisticsOrderStatusEnum,
  LogisticsStatusEnum,
  PageQueryPlanEnum
} from '@/resource/enum';
import { getLodop, loadCLodop } from '@/utils/lodop';
import { ImportFile } from '@/views/dialogs';
import BatchEditCost from './batch-edit-cost/batch-edit-cost.vue';
import BatchClose from './batch-close/batch-close.vue';
import ElectronicBillDelivery from './electronic-bill-delivery/electronic-bill-delivery.vue';
import { CustomColumnMixin } from '@/mixins/custom-column';
import { ApiResponse } from '@/api/axios';
import EditLogisticInfo from './edit-logistic-info/edit-logistic-info.vue';
import { NormalSelectOptions } from '@/resource/model/common';
import { QueryPlanOperationOption } from '@/components/os-query-plan/os-query-plan';
import { getBatchOperationErrorObjs } from '@/utils/handle-request-error';

@Component({
  name: 'Logistics',
  components: { ImportFile, BatchEditCost, ElectronicBillDelivery, EditLogisticInfo, BatchClose, OsQueryPlan }
})
export default class Logistics extends mixins(PagingMixin, CustomColumnMixin) {
  public tableOption: OsTableOption<LogisticsList> = {
    loading: false,
    data: [],
    fit: true,
    size: 'small'
  };

  public defaultColumnOptions: Array<OsTableColumn<LogisticsList>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true,
      fixed: true
    },
    {
      prop: 'code',
      label: 'logistics.code',
      minWidth: '200px',
      showOverflowTooltip: true
    },
    {
      prop: 'deliveryNoticeCode',
      label: 'logistics.relevanceDeliveryBill',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'customerName',
      label: 'logistics.relevanceCustomer',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'projectName',
      label: 'logistics.relevanceProject',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'requiredArriveTime',
      label: 'logistics.requiredArriveTime',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'arrivalTime',
      label: 'logistics.arrivalTime',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'childCount',
      label: 'logistics.childCount',
      showOverflowTooltip: true,
      minWidth: '100px'
    },
    {
      prop: 'sendMethod',
      label: 'logistics.logisticsInfo',
      showOverflowTooltip: true,
      minWidth: '200px'
    },
    {
      prop: 'weight',
      label: 'logistics.weight',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'price',
      label: 'logistics.price',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'status',
      label: 'logistics.status',
      showOverflowTooltip: true,
      minWidth: '150px'
    },
    {
      prop: 'logisticsStatus',
      label: 'logistics.logisticsStatus',
      showOverflowTooltip: true,
      minWidth: '150px'
    },
    {
      prop: 'newLogisticsTrack',
      label: 'logistics.newLogisticsTrack',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'receiver',
      label: 'logistics.receiverInfo',
      showOverflowTooltip: true,
      minWidth: '200px'
    },
    {
      prop: 'sender',
      label: 'logistics.senderInfo',
      showOverflowTooltip: true,
      minWidth: '200px'
    },
    {
      prop: 'subscribeStatus',
      label: 'logistics.subscribeStatus',
      showOverflowTooltip: true,
      minWidth: '150px'
    },
    {
      prop: 'remark',
      label: 'common.remark',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'createUserName',
      label: 'common.createUser',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'createTime',
      label: 'common.createTime',
      showOverflowTooltip: true,
      minWidth: '180px',
      formatter: (row: object): string => {
        return dateFormat((row as LogisticsList).createTime);
      }
    }
  ];

  public queryItemsOption: Array<OsQueryItemOption<LogisticsListQuery>> = [
    {
      type: 'Input',
      field: 'code',
      label: 'logistics.code',
      option: {
        placeholder: 'logistics.inputCode'
      }
    },
    {
      type: 'Input',
      field: 'logisticsNo',
      label: 'logistics.logisticsNo',
      option: {
        placeholder: 'logistics.inputLogisticsNo'
      }
    },
    {
      type: 'Select',
      field: 'statusList',
      label: 'logistics.status',
      option: {
        placeholder: 'logistics.seelctOrderStatus',
        multiple: true
      },
      optionData: this.getLogisticsOrderStatus
    },
    {
      type: 'Select',
      field: 'logisticsStatusList',
      label: 'logistics.logisticsStatus',
      option: {
        placeholder: 'logistics.seelctStatus',
        multiple: true
      },
      optionData: this.getLogisticsStatus
    },
    {
      type: 'Select',
      field: 'customerId',
      label: 'logistics.relevanceCustomer',
      option: {
        placeholder: 'logistics.selectCustomer',
        filterable: true
      },
      optionData: []
    },
    {
      type: 'DateRangePicker',
      field: 'deliveryTime',
      label: 'logistics.deliveryTime',
      option: {
        rangeSeparator: '~'
      }
    },
    {
      type: 'DateRangePicker',
      field: 'receivingTime',
      label: 'logistics.receivingTime',
      option: {
        rangeSeparator: '~'
      }
    },
    {
      type: 'Input',
      field: 'shopName',
      label: 'logistics.shopName',
      option: {
        placeholder: 'logistics.inputShopName'
      }
    },
    {
      type: 'Input',
      field: 'receivingCity',
      label: 'logistics.receivingCity',
      option: {
        placeholder: 'logistics.inputReceivingCity'
      }
    },
    {
      type: 'Select',
      field: 'expressCom',
      label: 'logistics.expressCompany',
      option: {
        placeholder: 'logistics.selectExpressCompany'
      },
      optionData: []
    },
    {
      type: 'Select',
      field: 'sendMethod',
      label: 'logistics.deliveryMode',
      option: {
        placeholder: 'logistics.selectDeliveryMode'
      },
      optionData: this.getDeliveryMode
    },
    {
      type: 'DateRangePicker',
      field: 'requiredArriveTime',
      label: 'logistics.requiredArriveTime',
      option: {
        rangeSeparator: '~'
      }
    },
    {
      type: 'Select',
      field: 'canTimeout',
      label: 'projectProduct.isOverdue',
      option: {
        placeholder: 'common.select'
      },
      optionData: (): NormalSelectOptions => {
        return [
          {
            label: translation('common.yes'),
            value: 1
          }
        ];
      }
    },
    {
      type: 'Select',
      field: 'toBeTimeout',
      label: 'projectProduct.toBeTimeout',
      option: {
        placeholder: 'common.select'
      },
      optionData: (): NormalSelectOptions => {
        return [
          {
            label: translation('common.yes'),
            value: 1
          }
        ];
      }
    },
    {
      type: 'Select',
      field: 'subscribeStatus',
      label: 'logistics.subscribeState',
      option: {
        placeholder: 'common.select'
      },
      optionData: (): NormalSelectOptions => {
        return [
          {
            label: translation('logistics.unSubscribe'),
            value: 1
          },
          {
            label: translation('logistics.subscribeSuccess'),
            value: 2
          }
        ];
      }
    }
  ];

  public operationOptions: Array<OperationOption> = [
    {
      type: 'primary',
      slot: 'start',
      label: 'logistics.delivery',
      operationType: 'delivery',
      icon: 'el-icon-takeaway-box',
      permissionCode: 'logistics:order:batchDelivery',
      disabled: true,
      handleClick: this.delivery
    },
    {
      type: 'primary',
      slot: 'start',
      label: 'logistics.importDelivery',
      operationType: 'import',
      icon: 'el-icon-upload2',
      permissionCode: 'logistics:order:importDelivery',
      handleClick: this.openImportDialog
    },
    {
      type: 'primary',
      slot: 'start',
      label: 'logistics.receiving',
      operationType: 'receiving',
      icon: 'el-icon-receiving',
      permissionCode: 'logistics:order:batchReceipt',
      disabled: true,
      handleClick: this.receiving
    },
    {
      type: 'primary',
      slot: 'start',
      label: 'logistics.takeElectronicBill',
      operationType: 'takeOrder',
      icon: 'el-icon-check',
      permissionCode: 'logistics:order:electronicsOrder',
      disabled: true,
      handleClick: this.takeElectronicBill
    },
    {
      type: 'primary',
      slot: 'start',
      label: 'logistics.waybillPrint',
      operationType: 'print',
      icon: 'el-icon-printer',
      permissionCode: 'logistics:order:batchPrint',
      disabled: true,
      handleClick: this.print
    },
    {
      type: 'primary',
      slot: 'start',
      label: 'logistics.cancleDelivery',
      operationType: 'cancleDelivery',
      icon: 'el-icon-close',
      permissionCode: 'logistics:order:batchCancel',
      disabled: true,
      handleClick: this.batchCancelDelivery
    },
    {
      type: 'primary',
      slot: 'start',
      label: 'button.close',
      operationType: 'batchClose',
      icon: 'el-icon-close',
      permissionCode: 'logistics:order:batchClose',
      disabled: true,
      handleClick: this.isShowBatchClose
    },
    {
      type: 'primary',
      slot: 'start',
      label: 'logistics.batchChangeCost',
      operationType: 'changeCost',
      icon: 'el-icon-money',
      permissionCode: 'logistics:order:batchEditPrice',
      disabled: true,
      handleClick: this.batchChangeCost
    }
  ];

  public customers: Array<{ customerId: number; customerName: string }> = [];

  public selectedRows: Array<LogisticsList> = [];

  /**
   * 页面标识
   */
  public code: number = PageQueryPlanEnum.logistics;
  /**
   * 查询方案编辑按钮
   */
  public queryPlanEndOption: Array<QueryPlanOperationOption> = [
    {
      id: 0,
      type: 'primary',
      slot: 'end',
      label: 'button.save',
      permissionCode: 'system:query:template:save',
      handleClick: (): void => {
        (this.$refs.OsQueryPlan as OsQueryPlan).dialogOpened();
      }
    },
    {
      id: 1,
      type: 'text',
      slot: 'end',
      label: 'button.edit',
      permissionCode: 'system:query:template:delete',
      handleClick: (): void => {
        (this.$refs.OsQueryPlan as OsQueryPlan).editDialogOpened();
      }
    }
  ];

  /**
   * 控制批量修改费用的dialog显隐
   */
  public editCostVisible = false;

  public importDeliveryVisible = false;

  public batchCloseVisible = false;

  /**
   * 电子面单下单dialog的显隐
   */
  public takeOrderVisible = false;

  /**
   * 选中的物流单ids
   */
  public selectedBills: Array<LogisticsList> = [];

  /**
   * 行操作配置
   */
  public rowOperationOption: RowOperation<LogisticsList> = {
    width: '80px',
    operations: [
      {
        operationType: 'edit',
        type: 'text',
        label: 'button.edit',
        icon: 'el-icon-edit',
        permissionCode: 'logistics:order:batchEditReceivingInfo',
        handleClick: (row: LogisticsList): void => {
          this.editLogisticInfo(row);
        },
        dynamicHidden: (row: LogisticsList): boolean => {
          return row.status !== LogisticsOrderStatusEnum.new;
        }
      }
    ]
  };

  public editLogisticVisible = false;

  public logisticInfo?: LogisticsList;

  public importFareVisible = false;

  private queryForm: Partial<LogisticsListQuery> = {
    code: '',
    customerId: undefined,
    deliveryTime: [],
    expressCom: '',
    logisticsNo: '',
    logisticsStatusList: [],
    receivingCity: '',
    receivingEndTime: undefined,
    receivingStartTime: undefined,
    receivingTime: [],
    requiredArriveTime: [],
    requiredArriveTimeEnd: undefined,
    requiredArriveTimeStart: undefined,
    sendEndTime: undefined,
    sendMethod: undefined,
    sendStartTime: undefined,
    shopName: '',
    statusList: [],
    canTimeout: undefined,
    toBeTimeout: undefined,
    subscribeStatus: undefined
  };
  private defaultQueryForm: Partial<LogisticsListQuery> = {
    code: '',
    customerId: undefined,
    deliveryTime: [],
    expressCom: '',
    logisticsNo: '',
    logisticsStatusList: [],
    receivingCity: '',
    receivingEndTime: undefined,
    receivingStartTime: undefined,
    receivingTime: [],
    requiredArriveTime: [],
    requiredArriveTimeEnd: undefined,
    requiredArriveTimeStart: undefined,
    sendEndTime: undefined,
    sendMethod: undefined,
    sendStartTime: undefined,
    shopName: '',
    statusList: [],
    canTimeout: undefined,
    toBeTimeout: undefined,
    subscribeStatus: undefined
  };

  public beforeCreate(): void {
    loadCLodop();
  }

  public activated(): void {
    this.queryClick();
  }
  public mounted(): void {
    this.queryClick();
  }
  public created(): void {
    this.initColumns(this.defaultColumnOptions, 's-logistics');
    this.getCustomers();
    this.getExpressCompanys();
  }

  public reloadData(): void {
    this.paging.currentPage = 1;
    // 重置查询方案
    (this.$refs.OsQueryPlan as OsQueryPlan).id = 0;
    this.clearSelection();
    this.loadData();
  }

  public pagingData(): void {
    this.loadData();
  }

  @debounce()
  public queryClick(): void {
    if (this.$route.query.queryPlanName) {
      this.paging.currentPage = 1;
      (this.$refs.OsQueryPlan as OsQueryPlan).selectQueryPlan(this.$route.query.queryPlanName as string);
      return;
    }
    this.loadData();
  }
  /**
   * 执行查询方案
   * @param json
   */
  public queryList(json: string): void {
    this.paging.currentPage = 1;
    Object.assign(this.queryForm, this.defaultQueryForm);
    Object.assign(this.queryForm, JSON.parse(json));
    this.loadData();
  }

  public linkToDetails(logistics: LogisticsList): void {
    this.$router.push({
      path: 'logistics-details',
      query: {
        id: logistics.id.toString()
      }
    });
  }

  public handleSelectionChange(selectedData: Array<LogisticsList>): void {
    this.selectedRows = selectedData;
  }

  public downloadTemplate(): void {
    (this.$refs.importDeliveryDialog as ImportFile).setDownloadLoading(true);
    const ids = this.selectedRows.filter(x => x.status === LogisticsOrderStatusEnum.new).map(x => x.id);
    logisticsService
      .downloadTemplate(ids)
      .then((blob: any) => {
        downloadFileByBlob(`${translation('logistics.templateName')}_${dateFormat(new Date())}.xlsx`, blob);
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        (this.$refs.importDeliveryDialog as ImportFile).setDownloadLoading(false);
      });
  }

  /**
   * 导入发货
   * @param path
   */
  public importDelivery(path: string): void {
    logisticsService
      .importDelivery(path)
      .then(() => {
        Message.success(translation('dialog.importSuccess'));
        this.reloadData();
        this.importDeliveryVisible = false;
      })
      .catch((error: ApiResponse<ImportRes>) => {
        handleImportError(error);
        (this.$refs.importDeliveryDialog as ImportFile).setLoading(false);
      });
  }

  public editCostSuccess(logistics: Array<LogisticsList>): void {
    logistics.forEach(item => {
      const find = this.tableOption.data.find(x => x.id === item.id);
      Object.assign(find, item);
    });
    this.clearSelection();
  }

  public editInfoSuccess(data: LogisticsList): void {
    const findItem = this.tableOption.data.find(x => x.id === data.id);
    Object.assign(findItem, data);
    this.clearSelection();
  }

  public getStatusClass(logistics: LogisticsList): string {
    switch (logistics.status) {
      case LogisticsOrderStatusEnum.new:
        return 'info-dot';
      case LogisticsOrderStatusEnum.inTransit:
        return 'primary-dot';
      case LogisticsOrderStatusEnum.closed:
        return 'success-dot';
      case LogisticsOrderStatusEnum.received:
        return 'success-dot';
      default:
        return 'danger-dot';
    }
  }

  public getStatusName(logistics: LogisticsList): string {
    if (!LogisticsOrderStatusEnum[logistics.status]) {
      return translation('common.unKnownStatus');
    }
    return `logisticsOrderStatus.${LogisticsOrderStatusEnum[logistics.status]}`;
  }

  public getlogisticsStatusClass(logistics: LogisticsList): string {
    switch (logistics.logisticsStatus) {
      case LogisticsStatusEnum.new:
        return 'info-dot';
      case LogisticsStatusEnum.signBack:
        return 'primary-dot';
      case LogisticsStatusEnum.customsClearance:
        return 'success-dot';
      default:
        return 'danger-dot';
    }
  }
  public getlogisticsStatusName(logistics: LogisticsList): string {
    if (!LogisticsStatusEnum[logistics.logisticsStatus]) {
      return 'common.unKnownStatus';
    }
    return `logisticsStatus.${LogisticsStatusEnum[logistics.logisticsStatus]}`;
  }

  public getSubscribeStatusClass(subscribeStatus: number): string {
    return subscribeStatus === 1 ? 'info-dot' : 'success-dot';
  }

  public getSubscribeStatusName(subscribeStatus: number): string {
    return subscribeStatus === 1 ? 'logistics.unSubscribe' : 'logistics.subscribeSuccess';
  }

  public getDeliveryModeName(mode: DeliveryModeEnum): string {
    return `deliveryMode.${DeliveryModeEnum[mode]}`;
  }

  public getReceiverAdress(logistics: LogisticsList): string {
    return `${logistics.receivingProvince ?? ''}${logistics.receivingCity ??
      ''} ${logistics.receivingDistrict ?? ''}${logistics.receivingAddress ?? ''}`;
  }

  public getSenderAdress(logistics: LogisticsList): string {
    return `${logistics.sendProvince ?? ''}${logistics.sendCity ?? ''}${logistics.sendDistrict ??
      ''}${logistics.senderAddress ?? ''}`;
  }

  public moreClick(command: 'delete' | 'export' | 'subscribe' | 'importFare'): void {
    switch (command) {
      case 'export':
        this.export();
        break;
      case 'subscribe':
        this.subscribeTrack();
        break;
      case 'delete':
        this.batchDeleteLogistics();
        break;
      case 'importFare':
        this.openImportFareDialog();
        break;
      default:
        break;
    }
  }

  /**
   * 打印电子面单
   * @param ids 物流单ids
   */
  public async printElectronicBill(ids: Array<number>): Promise<void> {
    try {
      const lodop = getLodop();
      const res = await logisticsService.batchPrint(ids);

      res.forEach(async data => {
        data.orderContent.split(',').forEach(async cur => {
          // 因面单为异步生成，根据官方建议延迟1-3s后获取，此处延迟2s再打印
          await this.sleep(2000);

          lodop?.PRINT_INIT('电子面单');
          lodop?.SET_PRINTER_INDEX(data.printName);
          lodop?.SET_PRINT_PAGESIZE(1, `${data.width}mm`, `${data.height}mm`, '');
          lodop?.ADD_PRINT_IMAGE(0, 0, `${data.width}mm`, `${data.height}mm`, `<img src="${cur}">`);
          // (不可变形)扩展缩放模式
          lodop?.SET_PRINT_STYLEA(0, 'Stretch', 2);
          // lodop?.PREVIEW();
          lodop?.PRINT();
        });
      });
    } catch (error) {
      messageError(error);
    }
  }

  /**
   * 模拟线程睡眠
   * @param millisecond 毫秒
   * @returns
   */
  public sleep(millisecond: number): Promise<void> {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve();
      }, millisecond);
    });
  }

  /**
   * 发货成功的回调
   */
  public handleDeliverySuccess(): void {
    this.clearSelection();
    this.pagingData();
  }

  /**
   * 物流单收发货信息修改后的回调
   */
  public handleLogisticEdited(): void {
    this.reloadData();
  }

  /**
   * 导入运费
   */
  public importFare(path: string): void {
    logisticsService
      .importFare(path)
      .then(() => {
        Message.success(translation('dialog.importSuccess'));
        this.reloadData();
        this.importFareVisible = false;
      })
      .catch((error: ApiResponse<ImportRes>) => {
        handleImportError(error);
        (this.$refs.importDeliveryDialog as ImportFile).setLoading(false);
      });
  }

  private loadData(): void {
    this.tableOption.loading = true;
    logisticsService
      .getList(this.queryForm, this.paging)
      .then(res => {
        this.tableOption.data = res.data;
        this.totalData = res.total;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }

  private getCustomers(): void {
    logisticsService
      .getProjectCustomer()
      .then(res => {
        this.customers = res;
        const customerQuery = this.queryItemsOption.find(x => x.field === 'customerId');
        customerQuery!.optionData = res.map(x => {
          return { label: x.customerName, value: x.customerId };
        });
      })
      .catch(error => {
        messageError(error);
      });
  }

  private async export(): Promise<void> {
    this.tableOption.loading = true;
    try {
      const idList = this.selectedRows.map(item => item.id!);
      const blob = await logisticsService.export(this.queryForm, idList);
      downloadFileByBlob(`${translation('logistics.export')}_${dateFormat(new Date())}.xlsx`, blob);
    } catch (error) {
      messageError(error);
    } finally {
      this.tableOption.loading = false;
    }
  }

  @Watch('queryForm.deliveryTime')
  private handleRequireArriveTimeChanged(value: Array<string>): void {
    if (!value || value.length === 0) {
      this.queryForm.sendStartTime = undefined;
      this.queryForm.sendEndTime = undefined;
      return;
    }
    if (value && value.length === 2) {
      this.queryForm.sendStartTime = dateFormat(value[0]);
      this.queryForm.sendEndTime = dateFormat(value[1], 'YYYY-MM-DD') + ' 23:59:59';
    }
  }

  @Watch('queryForm.receivingTime')
  private handleRequireDeliveryTimeChanged(value: Array<string>): void {
    if (!value) {
      this.queryForm.receivingStartTime = undefined;
      this.queryForm.receivingEndTime = undefined;
      return;
    }
    if (value && value.length === 2) {
      this.queryForm.receivingStartTime = dateFormat(value[0]);
      this.queryForm.receivingEndTime = dateFormat(value[1], 'YYYY-MM-DD') + ' 23:59:59';
    }
  }

  @Watch('selectedRows')
  private handleSelectedChanged(values: Array<LogisticsList>): void {
    this.operationOptions.forEach(x => {
      if (x.operationType !== 'import') {
        x.disabled = values.length === 0;
      }
    });
  }

  private clearSelection(): void {
    (this.$refs.logisticsTable as OsTable).clearSelection();
    this.selectedRows = [];
  }

  private getLogisticsOrderStatus(): Array<{ label: string; value: string }> {
    const logisticsStatus: Array<{ label: string; value: string }> = [];
    for (const key in LogisticsOrderStatusEnum) {
      if (isNaN(Number(key))) {
        logisticsStatus.push({
          label: this.$t(`logisticsOrderStatus.${key}`).toString(),
          value: LogisticsOrderStatusEnum[key]
        });
      }
    }
    return logisticsStatus;
  }
  private getLogisticsStatus(): Array<{ label: string; value: string }> {
    const logisticsStatus: Array<{ label: string; value: string }> = [];
    for (const key in LogisticsStatusEnum) {
      if (isNaN(Number(key))) {
        logisticsStatus.push({
          label: this.$t(`logisticsStatus.${key}`).toString(),
          value: LogisticsStatusEnum[key]
        });
      }
    }
    return logisticsStatus;
  }

  private async batchDeleteLogistics(): Promise<void> {
    const ids: Array<number> = this.selectedRows
      .filter(x => x.status === LogisticsOrderStatusEnum.new)
      .map(x => x.id);
    if (ids.length === 0) {
      Message.warning(translation('tip.selectReqirements'));
      this.clearSelection();
      return;
    }
    showWarningConfirm(translation('logistics.confirmDelete'))
      .then(async () => {
        try {
          await logisticsService.batchDelete(ids);
          this.reloadData();
          Message.success(translation('operationRes.deleteSuccess'));
        } catch (error) {
          messageError(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelDelete'));
      });
  }

  /**
   * 非第三方发货
   * @returns
   */
  private async delivery(): Promise<void> {
    try {
      const errorList: Array<any> = [];
      await showWarningConfirm(translation('logistics.confirmDelivery'));
      const selectList = this.selectedRows.filter(x => x.sendMethod === DeliveryModeEnum.TPL);

      for (const item of selectList) {
        if (!item.expressName) {
          // 校验物流公司是否填写
          errorList.push({
            key: item.code,
            code: 23116
          });
        }
        if (!item.logisticsNo) {
          // 校验物流公司是否填写
          errorList.push({
            key: item.code,
            code: 23115
          });
        }
        // if (this.getSenderAdress(item).length === 0) {
        //   // 校验发货地址

        // }
        if (!item.sender) {
          // 校验发货人姓名
          errorList.push({
            key: item.code,
            code: 23108
          });
        }
        if (!item.senderTel) {
          // 校验发货人电话
          errorList.push({
            key: item.code,
            code: 23109
          });
        }
      }

      if (errorList.length > 0) {
        messageErrors(getBatchOperationErrorObjs(errorList as Array<any>));
        return;
      }

      const ids: Array<number> = this.selectedRows
        .filter(x => x.status === LogisticsOrderStatusEnum.new)
        .map(x => x.id);
      if (ids.length === 0) {
        Message.warning(translation('tip.selectReqirements'));
        this.clearSelection();
        return;
      }

      await logisticsService.batchDelivery(ids);
      this.reloadData();
      Message.success(translation('operationRes.operationSuccess'));
    } catch (error) {
      if (error !== 'cancel') {
        messageErrors(error);
      }
    }
  }

  private receiving(): void {
    /** 过滤 待收货/在途中 */
    const ids: Array<number> = this.selectedRows
      .filter(x => x.status === LogisticsOrderStatusEnum.inTransit)
      .map(x => x.id);
    if (ids.length === 0) {
      Message.warning(translation('tip.selectReqirements'));
      this.clearSelection();
      return;
    }
    showWarningConfirm(translation('logistics.confirmReceiving'))
      .then(async () => {
        try {
          await logisticsService.batchReceiving(ids);
          this.reloadData();
          Message.success(translation('operationRes.operationSuccess'));
        } catch (error) {
          messageErrors(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelOperation'));
      });
  }

  /**
   * 批量打印电子面单
   */
  private async print(): Promise<void> {
    const ids = this.selectedRows
      .filter(
        x =>
          [LogisticsOrderStatusEnum.inTransit, LogisticsOrderStatusEnum.receivedGoods].includes(x.status) &&
          x.sendMethod === DeliveryModeEnum.TPL
      )
      .map(x => x.id);
    if (ids.length === 0) {
      Message.warning(translation('tip.selectReqirements'));
      this.clearSelection();
      return;
    }

    this.printElectronicBill(ids);
  }

  /**
   * 电子面单下单
   */
  private takeElectronicBill(): void {
    // 第三方物流，待发货状态的单据
    this.selectedBills = this.selectedRows.filter(
      x => x.status === LogisticsOrderStatusEnum.new && x.sendMethod === DeliveryModeEnum.TPL
    );
    if (this.selectedBills.length === 0) {
      Message.warning(translation('tip.selectReqirements'));
      this.clearSelection();
      return;
    }
    this.takeOrderVisible = true;
  }

  /**
   * 订阅物流轨迹
   */
  private subscribeTrack(): void {
    const ids: Array<number> = this.selectedRows
      .filter(
        x =>
          x.subscribeStatus === 1 &&
          [LogisticsOrderStatusEnum.inTransit, LogisticsOrderStatusEnum.receivedGoods].includes(x.status)
      )
      .map(x => x.id);
    if (ids.length === 0) {
      Message.warning(translation('tip.selectReqirements'));
      this.clearSelection();
      return;
    }
    logisticsService
      .subscribeTrack(ids)
      .then(() => {
        this.reloadData();
        Message.success(translation('operationRes.operationSuccess'));
      })
      .catch(error => {
        messageErrors(error);
      });
  }

  private batchChangeCost(): void {
    this.editCostVisible = true;
  }

  private openImportDialog(): void {
    this.importDeliveryVisible = true;
  }

  private editLogisticInfo(logisticInfo: LogisticsList): void {
    this.logisticInfo = logisticInfo;
    this.editLogisticVisible = true;
  }

  private async batchCancelDelivery(): Promise<void> {
    try {
      const ids = this.selectedRows
        .filter(x => x.status === LogisticsOrderStatusEnum.receivedGoods)
        .map(x => x.id);
      if (ids.length === 0) {
        Message.warning(translation('tip.selectReqirements'));
        this.clearSelection();
        return;
      }
      await showWarningConfirm(translation('logistics.cancelDeliveryTip'));
      await logisticsService.batchCancelDelivery(ids);
      this.reloadData();
      Message.success(translation('operationRes.operationSuccess'));
    } catch (error) {
      if (error !== 'cancel') messageError(error);
    }
  }
  private isShowBatchClose(): void {
    const ids = this.selectedRows
      .filter(x => [LogisticsOrderStatusEnum.inTransit, LogisticsOrderStatusEnum.error].includes(x.status))
      .map(x => x.id);
    if (ids.length === 0) {
      Message.warning(translation('tip.selectReqirements'));
      this.clearSelection();
      return;
    }
    this.batchCloseVisible = true;
  }
  private openImportFareDialog(): void {
    this.importFareVisible = true;
  }

  private getDeliveryMode(): NormalSelectOptions {
    return logisticsService.getDeliveryMode().map(x => {
      x.label = translation(x.label);
      return x;
    });
  }
  private async getExpressCompanys(): Promise<void> {
    try {
      const companys = (await electronicExpressWaybillService.getExpressCompanys()).map(x => {
        return {
          label: x.name,
          value: x.code
        };
      });
      const companyQuery = this.queryItemsOption.find(x => x.field === 'expressCom');
      if (companyQuery) {
        companyQuery.optionData = companys;
      }
    } catch (error) {
      messageError(error);
    }
  }
  @Watch('queryForm.requiredArriveTime')
  private handlerequiredArriveTimeChanged(value: Array<string>): void {
    if (!value || value.length === 0) {
      this.queryForm.requiredArriveTimeStart = undefined;
      this.queryForm.requiredArriveTimeEnd = undefined;
      return;
    }
    if (value && value.length === 2) {
      this.queryForm.requiredArriveTimeStart = dateFormat(value[0]);
      this.queryForm.requiredArriveTimeEnd = dateFormat(value[1], 'YYYY-MM-DD') + ' 23:59:59';
    }
  }
}
