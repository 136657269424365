import { logisticsService } from '@/api';
import { DialogMixin } from '@/mixins/dialog';
import { LogisticsList } from '@/resource/model';
import { messageErrors, translation } from '@/utils';
import { Message } from 'element-ui';
import { ElForm } from 'element-ui/types/form';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class BatchClose extends mixins(DialogMixin) {
  @Prop({ required: true, type: Array })
  public logistics!: Array<LogisticsList>;

  public form: { closeReason: string } = {
    closeReason: ''
  };

  public formRules = {
    closeReason: [
      {
        required: true,
        validator: (rule: any, value: number, callback: Function): void => {
          if (!value || value === 0) {
            callback(new Error(translation('logistics.inputCloseReason')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ]
  };

  public created(): void {
    this.title = 'logistics.batchCloseTitle';
  }

  public submit(): void {
    this.setLoading(true);
    (this.$refs.form as ElForm).validate((valid: boolean) => {
      if (valid) {
        const params: { idList: Array<number>; closeReason: string } = {
          idList: this.logistics.map(x => x.id!),
          closeReason: this.form.closeReason
        };
        logisticsService
          .batchClose(params)
          .then(() => {
            this.$emit('reload');
            Message.success(translation('operationRes.operationSuccess'));
            this.closeDialog();
          })
          .catch(error => {
            messageErrors(error);
          })
          .finally(() => {
            this.setLoading(false);
          });
      } else {
        this.setLoading(false);
      }
    });
  }
}
