import { logisticsService } from '@/api';
import { OsTableColumn, OsTableOption } from '@/components/os-table/os-table';
import { DialogMixin } from '@/mixins/dialog';
import { PagingMixin } from '@/mixins/paging';
import { BatchEditCostParams, LogisticsList } from '@/resource/model';
import { messageError, translation } from '@/utils';
import { ElForm } from 'element-ui/types/form';
import { ElInput } from 'element-ui/types/input';
import { cloneDeep } from 'lodash';
import { mixins } from 'vue-class-component';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class BatchEditCost extends mixins(DialogMixin, PagingMixin) {
  @Prop({ required: true, type: Array })
  public logistics!: Array<LogisticsList>;

  public tableOptions: OsTableOption<LogisticsList> = {
    loading: false,
    data: [],
    fit: true,
    size: 'small'
  };

  public columnOptions: Array<OsTableColumn<LogisticsList>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true,
      fixed: true
    },
    {
      prop: 'code',
      label: 'logistics.code',
      minWidth: '190px',
      showOverflowTooltip: true
    },
    {
      prop: 'customerName',
      label: 'logistics.relevanceCustomer',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'projectName',
      label: 'logistics.relevanceProject',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'receiver',
      label: 'logistics.receiverInfo',
      showOverflowTooltip: true,
      minWidth: '200px',
      formatter: (row: Object): string => {
        return `${(row as LogisticsList).receivingProvince ?? ''}${(row as LogisticsList).receivingCity ??
          ''} ${(row as LogisticsList).receivingDistrict ?? ''}${(row as LogisticsList).receivingAddress ??
          ''}`;
      }
    },
    {
      prop: 'price',
      label: 'logistics.logisticsCost',
      minWidth: '150px'
    },
    {
      prop: 'weight',
      label: 'logistics.weight',
      minWidth: '150px'
    }
  ];

  public selectedRows: Array<LogisticsList> = [];

  public form: { price: number | undefined; weight: number | undefined } = {
    price: undefined,
    weight: undefined
  };

  public formRules = {
    price: [
      {
        required: true,
        validator: (rule: any, value: number, callback: Function): void => {
          if (!value || value === 0) {
            callback(new Error(translation('logistics.inputCost')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    weight: [
      {
        required: true,
        validator: (rule: any, value: number, callback: Function): void => {
          if (!value || value === 0) {
            callback(new Error(translation('logistics.inputWeight')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ]
  };

  public created(): void {
    this.title = 'logistics.batchChangeCost';
    this.tableOptions.data.push(...cloneDeep(this.logistics));
    this.tableOptions.data.forEach(x => {
      Vue.set(x, 'showPriceInput', false);
      Vue.set(x, 'showWeightInput', false);
      x.price ?? Vue.set(x, 'price', 0);
      x.weight ?? Vue.set(x, 'weight', 0);
    });
  }

  public handlePriceClick(logistics: LogisticsList & { showPriceInput: boolean }): void {
    logistics.showPriceInput = true;
    this.$nextTick(() => {
      (this.$refs.costInput as ElInput).focus();
    });
  }

  public handlePriceBlur(logistics: LogisticsList & { showPriceInput: boolean }): void {
    if (!logistics.price) {
      logistics.price = 0;
    }
    logistics.showPriceInput = !logistics.showPriceInput;
  }

  public handleWeightClick(logistics: LogisticsList & { showWeightInput: boolean }): void {
    logistics.showWeightInput = true;
    this.$nextTick(() => {
      (this.$refs.weightInput as ElInput).focus();
    });
  }

  public handleWeightBlur(logistics: LogisticsList & { showWeightInput: boolean }): void {
    if (!logistics.weight) {
      logistics.weight = 0;
    }
    logistics.showWeightInput = !logistics.showWeightInput;
  }

  public handleLogisticsSelection(logistics: Array<LogisticsList>): void {
    this.selectedRows = logistics;
  }

  public batchSet(): void {
    (this.$refs.form as ElForm).validate(async (valid: boolean) => {
      if (!valid) {
        return;
      }
      this.tableOptions.data.forEach(x => {
        x.price = this.form.price!;
        x.weight = this.form.weight!;
      });
    });
  }

  public submit(): void {
    this.setLoading(true);
    const params: Array<BatchEditCostParams> = this.tableOptions.data.map(x => {
      return {
        id: x.id,
        price: x.price,
        weight: x.weight
      };
    });
    logisticsService
      .batchEditCost(params)
      .then(() => {
        this.$emit('edit-cost-success', this.tableOptions.data);
        this.closeDialog();
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.setLoading(false);
      });
  }
}
